<template>
  <section class="bando-head">
    <h1>Liste des bénéficiaires</h1>
  </section>

  <div class="container">
    <section class="content">
      <h2 class="title">Rechercher un bénéficiaire</h2>

      <!-- SEARCH -->
      <div class="bloc-search bloc-shadow">
        <form>
          <div class="row">
            <div class="col col-md-9">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  name="beneficiaire"
                  id="beneficiaire"
                  placeholder="Ex : Martine PICARD"
                />
              </div>
            </div>
            <!-- <div class="col col-md-3 text-right">
              <button type="submit" class="btn btn-primary">Rechercher</button>
            </div> -->
          </div>
        </form>
      </div>

      <!-- TABLE -->
      <table
        id="table-rdv"
        class="table table-striped bloc-shadow dt-responsive"
      >
        <thead>
          <tr>
            <th></th>
            <th class="text-uppercase">Nom</th>
            <th class="text-uppercase">Prénom</th>
            <th class="text-uppercase">Code Postal et Commune</th>
            <th class="text-uppercase">Référence</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>

      <!-- BTN EXPORT -->
      <!-- <div class="text-center">
        <router-link
          :to="{ name: 'BeneficiaireAjout' }"
          class="btn btn-primary mb-3"
        >
          Ajouter un bénéficiaire
        </router-link>
        <button type="button" class="btn btn-primary mx-4 mb-3">
          Importer des bénéficiaires
        </button>
        <button type="button" class="btn btn-primary mb-3">
          Exporter les bénéficiaires
        </button>
      </div> -->
    </section>
  </div>
</template>

<script>
const $ = window.$;

export default {
  name: "BeneficiairesListe",
  components: {},
  data() {
    return {
      beneficiaires: [],
      error: false,
      loading: false
    };
  },
  mounted() {
    const dataTable = $("#table-rdv").DataTable({
      dom: "<<t>r<lip>>",
      columnDefs: [
        {
          orderable: false,
          targets: 0
        }
      ],
      ajax: {
        url: process.env.VUE_APP_API + "/beneficiaire/search",
        // dataSrc: (json) => json,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-token")
        }
      },
      processing: true,
      serverSide: true,
      columns: [
        {
          data: "uuid",
          render: data =>
            '<a href="/edition-beneficiaire" class="edit-placeholder" id="' +
            data +
            '"><i class="fal fa-eye" aria-hidden="true"></i></a>'
        },
        {
          data: "nom",
          className: "text-uppercase"
        },
        {
          data: "prenom"
        },
        {
          data: "adresse",
          className: "text-uppercase"
        },
        {
          data: "reference"
        }
      ],
      order: [[1, "asc"]],
      pageLength: 10,
      language: {
        lengthMenu: "Par page : _MENU_",
        zeroRecords: "Aucun résultat",
        processing: "Chargement en cours...",
        paginate: {
          first: "Premier",
          last: "Dernier",
          next: "Suivant",
          previous: "Précédent"
        }
      },
      responsive: true
    });
    $(".dataTables_length select").addClass("custom-select");
    $("#beneficiaire").keyup(() => {
      dataTable.search($("#beneficiaire").val()).draw();
    });

    const self = this;
    $("tbody", this.$refs.table).on("click", ".edit-placeholder", function(e) {
      e.preventDefault();
      self.$router.push("/edition-beneficiaire/" + $(this).attr("id"));
    });
  }
};
</script>

<style scoped lang="scss">
div.dataTables_processing {
  z-index: 1;
}
</style>
